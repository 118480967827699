<div class="side-container">
    <div class="side-container__left-column side-container_main-column">

        <mat-card class="session">

            <mat-card-title class="session__title">Welcome to 
                <span class="session__title-brand">LOWEN<span class="secondary-text">GG</span></span>!
            </mat-card-title>

            <form [formGroup]="loginForm" (ngSubmit)="login()" class="session__form">

                <mat-form-field appearance="fill">
                    <mat-label>Username</mat-label>
                    <input matInput type="text" formControlName="username">
                    <mat-error>
                        <error-messages [control]="loginForm.controls['username']"></error-messages>
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="password">
                    <mat-error>
                        <error-messages [control]="loginForm.controls['password']"></error-messages>
                    </mat-error>
                </mat-form-field>

                <mat-checkbox formControlName="rememberMe">Remember me?</mat-checkbox>

                <div class="error-message text-center" *ngIf="errorMessage">
                    {{errorMessage}}
                </div>

                <button mat-raised-button class="btn-secondary session__submit-btn" *ngIf="!loading" type="submit">
                    Login
                </button>

                <mat-spinner *ngIf="loading" [diameter]="35"></mat-spinner>

                <a class="session__details" [routerLink]="'/'">Forgot Password?</a>

            </form>

        </mat-card>
    </div>

    <div class="side-container__right-column side-container__right-column hidden-smaller-lg">
        <img src="../../../../assets/images/bg-image.png" alt="image" width="100%" height="100%">
    </div>
</div>