import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { QueueType } from 'src/app/shared/types/lol/queue-type';
import { RoomStatus } from 'src/app/shared/types/room/room-status';
import { Room } from '../../models/room/room';
import { RoomsService } from './../../../core/services/rooms/rooms.service';

@Component({
    selector: 'room-card',
    templateUrl: './room-card.component.html',
    styleUrls: ['./room-card.component.scss']
})
export class RoomCardComponent implements OnInit {

    @Input() room: Room;

    roomStatus = RoomStatus;
    queueType = QueueType;

    constructor(private router: Router, private roomsService: RoomsService) { }

    ngOnInit() {
    }

    viewRoom() {
        // this.roomsService.room = this.room;
    }

}
