import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'shortNumber'})
export class ShortNumberPipe implements PipeTransform {
    isNegative: boolean = false;
    integerValue: boolean = false;
    
    transform(value: any, integerValue: boolean = false) {
        this.integerValue = integerValue;
        if (isNaN(value)) {
            return value;
        }
        if(value < 0){
            this.isNegative = true;
        } else {
            this.isNegative = false;
        }
        value = Math.abs(value);
        //
        if(value < 999){
            return this.checkIfNegative(value);
        }
        if (value > 999 && value < 1000000) {
            return this.checkIfNegative(Number.parseFloat((value / 1000).toFixed(1))) + "k";
        }
        if (value < 10000000) {
            return this.checkIfNegative(Number.parseFloat((value / 1000000).toFixed(1))) + "M";
        }
        if (value < 1000000000) {
            return this.checkIfNegative(Number.parseFloat((value / 1000000).toFixed(1))) + "M";
        }
        if (value < 1000000000000) {
            return this.checkIfNegative(Number.parseFloat((value / 1000000000).toFixed(1))) + "B";
        }
        return "1T+" ? !this.isNegative : "-1T+";
    }

    checkIfNegative(value: number){
        if(this.isNegative){
            return '-' + value;
        } else {
            return !this.integerValue ? value.toFixed(1) : value;
        }
    }
}