import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: 'game-recap',
    templateUrl: './game-recap.component.html',
    styleUrls: ['./game-recap.component.scss']
})
export class GameRecapComponent implements OnInit {

    @Input() game: any;
    @Input() showSummoner: boolean = true;

    championIconUrl: string;

    ngOnInit() {
        const durationMinutes = (this.game.time / 60).toFixed(2);
        this.game.durationString = durationMinutes.toString().replace('.', ':');
        this.championIconUrl = "https://ddragon.leagueoflegends.com/cdn/12.19.1/img/profileicon/29.png"
    }

    getRule(rule): string{
      const action = this.getAction(rule.action);
      let operation = this.getOperation(rule.operation);
      let value = '';

      if (rule.action === 'VALUE' || rule.action === 'PERCENTAGE'){
        value = rule.value;
      }else {
        operation = "";
      }

      if (rule.action === 'PERCENTAGE'){
        value += "%"
      }

      return action + " " + rule.variable + " " + operation + " " + value
    }

  getOperation(operation: string): string {
    switch(operation) {
      case 'GRATER_EQUALS':
        return '>=';
      case 'LESS':
        return '<';
      case 'LESS_EQUALS':
        return '<=';
      case 'GRATER':
        return '>';
    }
  }

  getAction(action: string): string {
    switch(action) {
      case 'VALUE':
        return '';
      case 'PERCENTAGE':
        return 'Min';
      case 'MAX':
        return 'Max';
      case 'MIN':
        return 'Min';
      case 'FIRST':
        return 'First';
      default:
        return '';
    }
  }


}

