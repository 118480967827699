<div class="side-container">
    <div class="side-container__left-column hidden-smaller-lg h-100">
        <img src="../../../../assets/images/bg-image.png" alt="image" width="100%" height="100%">
    </div>

    <div class="side-container__right-column side-container_main-column d-flex">

        <mat-card class="session side-container__content">
            <mat-card-title class="session__title">Welcome to
                <span class="session__title-brand">LOWEN<span class="secondary-text">GG</span></span>!
            </mat-card-title>

            <form [formGroup]="signUpForm" (ngSubmit)="signUp()" class="session__form flex flex_two-col">

                <mat-form-field appearance="fill">
                    <mat-label>Username</mat-label>
                    <input matInput type="text" formControlName="username">
                    <mat-error>
                        <error-messages [control]="signUpForm.controls['username']"></error-messages>
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Nickname</mat-label>
                    <input matInput type="text" formControlName="nickname">
                    <mat-error>
                        <error-messages [control]="signUpForm.controls['nickname']"></error-messages>
                    </mat-error>
                </mat-form-field>


                <mat-form-field appearance="fill">
                    <mat-label>First Name</mat-label>
                    <input matInput type="text" formControlName="firstName">
                    <mat-error>
                        <error-messages [control]="signUpForm.controls['firstName']"></error-messages>
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Last Name</mat-label>
                    <input matInput type="text" formControlName="lastName">
                    <mat-error>
                        <error-messages [control]="signUpForm.controls['lastName']"></error-messages>
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Email</mat-label>
                    <input matInput type="text" formControlName="email">
                    <mat-error>
                        <error-messages [control]="signUpForm.controls['email']"></error-messages>
                    </mat-error>
                </mat-form-field>


                <mat-form-field appearance="fill">
                    <mat-label>Date of birth</mat-label>
                    <input matInput [matDatepicker]="datepicker" [max]="maxDate" formControlName="dateOfBirth"
                        (click)="datepicker.open()" (focus)="datepicker.open()">
                    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                    <mat-datepicker #datepicker></mat-datepicker>
                    <mat-error>
                        <error-messages [control]="signUpForm.controls['dateOfBirth']"></error-messages>
                    </mat-error>
                </mat-form-field>


                <mat-form-field appearance="fill">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="password" [errorStateMatcher]="errorStateMatcher">
                    <mat-error>
                        <error-messages [control]="signUpForm.controls['password']"></error-messages>
                    </mat-error>
                </mat-form-field>


                <mat-form-field appearance="fill">
                    <mat-label>Confirm password</mat-label>
                    <input matInput type="password" formControlName="confirmPassword" [errorStateMatcher]="errorStateMatcher">
                    <mat-error>
                        <error-messages [control]="signUpForm.controls['confirmPassword']"></error-messages>
                    </mat-error>
                </mat-form-field>

                <div class="error-message w-100 text-center" *ngIf="errorMessage">
                    {{errorMessage}}
                </div>

                <button mat-raised-button class="btn-secondary session__submit-btn" *ngIf="!loading" type="submit">
                    Create account
                </button>
                <div class="w-100">
                    <mat-spinner *ngIf="loading" [diameter]="35"></mat-spinner>
                </div>

            </form>

        </mat-card>

    </div>
</div>