import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { UserSessionService } from '../../../core/services/user/user-session.service';
import { CustomErrorStateMatcher } from '../../../shared/components/error-state-matcher/custom-error-state-matcher';
import { PasswordValidator } from '../../../shared/utils/password-validator';
import { Router } from '@angular/router';

@Component({
    selector: 'sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
    signUpForm = this.formBuilder.group({
        username: ['', Validators.required],
        nickname: ['', Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        dateOfBirth: ['', Validators.required],
        password: ['', [Validators.required, PasswordValidator.validatePassword]],
        confirmPassword: ['', Validators.required]
    }, {
        validators: [this.validateConfirmPassword]
    });

    errorStateMatcher = new CustomErrorStateMatcher();
    maxDate = new Date();
    loading: boolean;
    errorMessage: string;

    constructor(private formBuilder: FormBuilder, private userSessionService: UserSessionService, private router: Router) {
    }

    ngOnInit(): void {

    }

    async signUp() {
        if (this.signUpForm.invalid) {
            return;
        }
        this.loading = true;
        try {
            const response = await this.userSessionService.register(this.signUpForm.value);
            this.router.navigateByUrl('/home');
        } catch (error) {
            if (error.status === 409) {
                this.errorMessage = 'Email is already registered.';
            }
        } finally {
            this.loading = false;
        }
    }

    // Returning null to formGroup because error is being set in confirmPasswordControl
    validateConfirmPassword(formGroup: AbstractControl): ValidationErrors {
        const passwordControl = formGroup.get('password');
        const confirmPasswordControl = formGroup.get('confirmPassword');
        if (!confirmPasswordControl.value) {
            confirmPasswordControl.setErrors({ required: true });
            return null;
        }
        if (passwordControl.value !== confirmPasswordControl.value) {
            confirmPasswordControl.setErrors({ mismatchedPasswords: true });
            return null;
        } else {
            confirmPasswordControl.setErrors(null);
            return null;
        }
    }
}
