import { Component, Input, OnInit } from "@angular/core";
import { RoomAchievement } from '../../models/room/room-achievement';
import achievementData from '../../json/achievement.json';
import {StringUtils} from "../../utils/string-utils";
import { DatePipe } from '@angular/common';

@Component({
    selector: 'achievement-card-2',
    templateUrl: './achievement-card-2.component.html',
    styleUrls: ['./achievement-card-2.component.scss']
})
export class AchievementCard2Component implements OnInit {

  constructor(private datePipe: DatePipe) {}


  @Input() achievement: any;

  accounts: any;
  text1: string;
  text2: string;
  text3: string;
  text4: string;
  backgroundUrl: string;
  show: boolean;
  isEmpty: boolean;

  ngOnInit() {
    this.accounts = this.achievement.accounts;

    this.isEmpty = !this.accounts || this.accounts.length === 0;

    if (!this.isEmpty){
      this.accounts.sort((a, b) => a.name.length - b.name.length);
    }

    const achievementTemplate = achievementData.achievements.find(a => a.name === this.achievement.name);

    if (achievementTemplate !== undefined){
      this.show = true;
      this.text1 = this.replaceVariables(achievementTemplate.text1, this.achievement);
      this.text2 = this.replaceVariables(achievementTemplate.text2, this.achievement);
      this.text3 = this.replaceVariables(achievementTemplate.text3, this.achievement);
      this.text4 = this.replaceVariables(achievementTemplate.text4, this.achievement);
      if (this.achievement.champion != null){
        this.achievement.champion = this.parseChampionName(this.achievement.champion.toLowerCase());
      }
      this.backgroundUrl = this.replaceVariables(achievementTemplate.imageUrl, this.achievement);
      if (this.backgroundUrl.endsWith("/_0.jpg")){
        this.backgroundUrl = 'https://ddragon.leagueoflegends.com/cdn/12.19.1/img/profileicon/29.png';
      }
    } else {
      this.show = false;
    }

  }

  replaceVariables(input: string, achievement: any): string {
    let replacedInput = input;

    replacedInput = replacedInput.replace("{value}",
      achievement.value !== null && achievement.value !== undefined ? achievement.value : "");
    replacedInput = replacedInput.replace("{champion}",
      achievement.champion !== null && achievement.champion !== undefined ? achievement.champion : "");
    replacedInput = replacedInput.replace("{date}",
      achievement.date !== null && achievement.date !== undefined ? this.datePipe.transform(achievement.date, 'yyyy-MM-dd hh:mm') : "");

    return replacedInput;
  }

  parseChampionName(name: string): string{
    let parts = name.split('_');
    parts = parts.map(part => StringUtils.capitalize(part));
    return parts.join('');
  }

}


