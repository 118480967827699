import {Injectable} from '@angular/core';
import {User} from '../../shared/models/user/user';
import {UserAuthorization} from '../../shared/models/user/user-authorization';

const USER_AUTHORIZATION_STORAGE_KEY = 'user-authorization';
const REMEMBER_ME_STORAGE_KEY = 'remember-me';

@Injectable()
export class UserSessionStorage {
    accessToken: string;

    public setUserAuthorization(userAuthorization: UserAuthorization, rememberMe: boolean): void {
        this.accessToken = userAuthorization.authorization.accessToken;
        if (rememberMe) {
            localStorage.setItem(USER_AUTHORIZATION_STORAGE_KEY, JSON.stringify(userAuthorization));
        } else {
            sessionStorage.setItem(USER_AUTHORIZATION_STORAGE_KEY, JSON.stringify(userAuthorization));
        }
        localStorage.setItem(REMEMBER_ME_STORAGE_KEY, String(rememberMe));
    }

    public getUserAuthorization(): UserAuthorization {
        if (localStorage.getItem(REMEMBER_ME_STORAGE_KEY)) {
            return JSON.parse(localStorage.getItem(USER_AUTHORIZATION_STORAGE_KEY));
        } else {
            return JSON.parse(sessionStorage.getItem(USER_AUTHORIZATION_STORAGE_KEY));
        }
    }

    public dropSession(): void {
        sessionStorage.removeItem(USER_AUTHORIZATION_STORAGE_KEY);
        localStorage.removeItem(USER_AUTHORIZATION_STORAGE_KEY);
    }

    public getUser(): User {
        const userAuthorization = this.getUserAuthorization();
        if (userAuthorization != null){
            return userAuthorization.user;
        }
        return null;
    }

    public getAccessToken(): string {
        if (this.accessToken != null && this.accessToken !== '') {
            return this.accessToken;
        } else {
            const userAuth = this.getUserAuthorization();
            return userAuth ? userAuth.authorization.accessToken : null;
        }
    }
}
