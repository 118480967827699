import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { AchievementCard2Component } from "./components/achievement-card-2/achievement-card-2.component";
import { AchievementCardComponent } from './components/achievement-card/achievement-card.component';
import { ChampionTagComponent } from './components/champion-tag/champion-tag.component';
import { ColorNumberComponent } from "./components/color-number/color-number.component";
import { ErrorMessagesComponent } from './components/error-messages/error-messages.component';
import { GameRecapComponent } from './components/game-recap/game-recap.component';
import { LiveGameComponent } from './components/live-game/live-game.component';
import { RoomCardComponent } from './components/room-card/room-card.component';
import { RoomQueueTagComponent } from './components/room-queue-tag/room-queue-tag.component';
import { NotificationSnackbarComponent } from './components/snackbar-component/notification-snackbar.component';
import { StreakComponent } from "./components/streak-component/streak.component";
import { StreakPositionComponent } from "./components/streak-position-component/streak-position.component";
import { SummonerTagComponent } from './components/summoner-tag/summoner-tag.component';
import { UtilsModule } from './utils/utils.module';





export const CUSTOM_DATE_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY',
    },
};

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,

        // Material Modules
        MatCardModule,
        MatToolbarModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatGridListModule,
        MatMenuModule,
        MatSelectModule,
        MatTooltipModule,
        MatTabsModule,
        MatRippleModule,
        MatSnackBarModule,
        MatExpansionModule,


        IvyCarouselModule,

        UtilsModule

    ],
    declarations: [
        ErrorMessagesComponent,
        RoomCardComponent,
        RoomQueueTagComponent,
        AchievementCardComponent,
        AchievementCard2Component,
        GameRecapComponent,
        LiveGameComponent,
        SummonerTagComponent,
        ChampionTagComponent,
        NotificationSnackbarComponent,
        StreakComponent,
        StreakPositionComponent,
        ColorNumberComponent
    ],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,

        // Material Modules
        MatCardModule,
        MatToolbarModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatMenuModule,
        MatSidenavModule,
        MatListModule,
        MatSelectModule,
        MatGridListModule,
        MatTooltipModule,
        MatTabsModule,
        MatRippleModule,
        MatSnackBarModule,
        MatExpansionModule,

        IvyCarouselModule,

        UtilsModule,

        // Custom Components
        ErrorMessagesComponent,
        RoomCardComponent,
        RoomQueueTagComponent,
        AchievementCardComponent,
        AchievementCard2Component,
        GameRecapComponent,
        LiveGameComponent,
        SummonerTagComponent,
        ChampionTagComponent,
        StreakComponent,
        StreakPositionComponent,
        ColorNumberComponent
    ]
})
export class SharedModule {
}

