import {Component, Input} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {StringUtils} from '../../utils/string-utils';

@Component({
    selector: 'error-messages',
    templateUrl: './error-messages.component.html',
    styleUrls: ['./error-messages.component.scss']
})
export class ErrorMessagesComponent {

    @Input() control: AbstractControl;

    // Dictionary with function as values to use parameters.
    errorMessages = {
        required: ((params) => `This field is required`),
        minlength: ((params) => `Must be at least ${params.requiredLength} characters long`),
        maxlength: ((params) => `Must be less than ${params.requiredLength} characters long`),
        pattern: ((params) => `Not matching pattern: ${params.requiredPattern}`),
        missingLowercase: ((params) => `Must contain a lowercase letter`),
        missingUppercase: ((params) => `Must contain an uppercase letter`),
        missingDigit: ((params) => `Must contain a digit`),
        missingSpecialCharacter: ((params) => `Must contain a special character ($, %, &, #, *, @)`),
        mismatchedPasswords: ((params) => `Passwords don't match`),
        email: ((params) => `Must be a valid email`),
        unknown: (error) => `Unkwon error: ${error}`
    };

    shouldShowError() {
        return this.control?.errors && this.control.invalid;
    }

    getErrors() {
        return Object.keys(this.control.errors).map(error =>
            this.getErrorMessage(error, this.control.errors[error])
        );
    }

    getErrorMessage(error: string, params: any) {
        if (this.errorMessages[error] != null) {
            return this.errorMessages[error](params);
        } else {
            return this.errorMessages['unknown'](error);
        }
    }

    // ??? This field vs Username ---> firstName => FirstName? ???
    getControlName() {
        if (this.control.parent.controls != null) {
            const controlsKeys = Object.keys(this.control.parent.controls);
            const controlName = controlsKeys.find(key => this.control.parent.controls[key] === this.control);
            if (controlName != null && controlName !== '') {
                return StringUtils.capitalize(controlName);
            }
            return 'This field';
        }
        return 'This field';
    }
}
