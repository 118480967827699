import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';


@Pipe({name: 'timeFromNow'})
export class TimeFromNowPipe implements PipeTransform {

    transform(value: any, withoutSuffix: boolean = false) {
        if (value) {
            return moment(value).fromNow(withoutSuffix);
        }
        return value;
    }

}