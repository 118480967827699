import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserSessionService } from '../../../core/services/user/user-session.service';
import { NotificationService } from 'src/app/shared/utils/notification.service';
import { NotificationType } from 'src/app/shared/types/utils/score-type';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

    loginForm = this.formBuilder.group({
        username: ['', Validators.required],
        password: ['', Validators.required],
        rememberMe: [true]
    });

    loading: boolean;
    errorMessage: string;

    constructor(private formBuilder: FormBuilder, private userSessionService: UserSessionService, private router: Router,
        private notificationService: NotificationService) {
    }

    ngOnInit(): void {
    }

    login() {
        this.loading = true;
        if (this.loginForm.invalid) {
            this.loading = false;
            return;
        }
        this.userSessionService.login(this.loginForm.value.username,
            this.loginForm.value.password,
            this.loginForm.value.rememberMe
        ).then(res => {
            this.loading = false;
            // TODO: If url needs authentication, we have to keep that url and redirect the user to that after log in.
            this.router.navigate(['/home'])
        }).catch(error => {
            if (error.status === 404) {
                this.errorMessage = "Incorrect username or password."
            }
            this.loading = false;
        });
    }

    // Example custom validator.
    static validateAAA(control: AbstractControl): { [key: string]: boolean } | boolean {
        if (control.value.length > 2 && control.value.substring(0, 3) === 'AAA') {
            return true;
        }
        return { 'validateAAA': true };
    }
}

