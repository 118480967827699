import {Injectable} from "@angular/core";
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {Observable} from "rxjs/internal/Observable";
import {RoomAccount} from '../../../shared/models/room/room-account';
import {environment} from './../../../../environments/environment';
import {RoomData} from './../../../shared/models/room/room-data';
import {RoomSearchFilter} from './../../../shared/models/room/room-search';
import {RestService} from './../http/rest.service';
import data from 'src/app/shared/json/data.json';
import {RoomAchievement} from "../../../shared/models/room/room-achievement";
import {RoomUser} from "../../../shared/models/room/room-user";
import {RoomAccountSnapshot} from "../../../shared/models/room/room-account-snapshot";
import {LolAccount} from "../../../shared/models/lol/lol-account";
import {ChampionStats} from "../../../shared/models/champion/champion-stats";
import {RoomAccountProgress} from "../../../shared/models/room/room-account-progress";

@Injectable()
export class RoomsService {

    private roomData: BehaviorSubject<RoomData> = new BehaviorSubject<RoomData>(null);

    public roomData$: Observable<RoomData> = this.roomData.asObservable();


    constructor(private restService: RestService) { }

    searchRooms(searchFilter: RoomSearchFilter) {
      if (environment.onlyView) {
        return data.roomsPage;
      } else {
        const url = `${environment.apiBase}/lol/room/search/`;
        return this.restService.put(url, searchFilter);
      }
    }

    async refreshRoom(id: number) {
      const url = `${environment.apiBase}/lol/room/${id}/refresh`;
      return this.restService.securedPut(url, null);
    }

  async getRoomData(id: number) {
    this.clearRoom();

    if (environment.onlyView){
      const error = new Error(`Not found room with ID ${id}`);

      const rooms = data.rooms;

      const room = rooms.find(r => r.lolRoomGeneralDTO.room.id === id);

      if (room) {
        const lolRoomGeneralDTO = room.lolRoomGeneralDTO;

        if (lolRoomGeneralDTO) {
          this.roomData.next(this.mapRoomDataFromJSON(lolRoomGeneralDTO));
        } else {
          this.roomData.error(error);
        }
      } else {
        this.roomData.error(error);
      }
    } else {
      const url = `${environment.apiBase}/lol/room/${id}/general`;
      try {
        const response = await this.restService.get(url);
        this.roomData.next(response);
      } catch (error) {
        this.roomData.error(error)
      }
    }

  }



    async getRoomAccountData(roomId: number, accountId: number): Promise<RoomAccount> {

      if (environment.onlyView){
        const rooms = data.rooms;

        const room = rooms.find(r => r.lolRoomGeneralDTO.room.id === roomId);

        return this.mapRoomAccountFromJSON(room.lolRoomAccounts.find(lra => lra.lolAccount.id === accountId));
      }else {
        const url = `${environment.apiBase}/lol/room/account/${roomId}/${accountId}`;
        try {
          return await this.restService.get(url);
        } catch (error) {
          return error;
        }
      }
    }

    async getRoomAccountProgress(roomId: number, accountId: number, frequency: string): Promise<RoomAccount> {
        const url = `${environment.apiBase}/lol/room/account/${roomId}/${accountId}?period=${frequency}`;
        try {
            const response = await this.restService.get(url);
            return response;
        } catch (error) {
            return error;
        }
    }

    clearRoom() {
        this.roomData.next(null);
    }

    getRoomId() {
        return this.roomData.getValue().room.id;
    }


  mapRoomDataFromJSON(json: any): RoomData {
    return {
      room: json.room,
      ladder: json.ladder as RoomAccountSnapshot[],
      users: json.users as RoomUser[],
      achievements: json.achievements as RoomAchievement[],
      lastGames: json.lastGames as any[],
      liveGames: json.liveGames as any[],
      lastAchievements: json.lastAchievements as RoomAchievement[]
    };
  }

  mapRoomAccountFromJSON(json: any): RoomAccount {
    return {
      progessPeriod: json.progessPeriod,
      lolAccount: json.lolAccount as LolAccount,
      lastSnapshot: json.lastSnapshot as RoomAccountSnapshot,
      games: json.games as any[],
      bestChampions: json.bestChampions as ChampionStats[],
      roomAccountProgress: json.roomAccountProgress as RoomAccountProgress[]
    };
  }
}
