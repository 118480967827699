<section class="live-game">
    <div class="live-game__header live-game-header">
        <!-- <img class="live-game-header__img" src="../../../../../../assets/images/champions/amumu.png"> -->
        <img class="live-game-header__img" src="{{championIconUrl}}">
        <span class="live-game-header__title">{{game.champion | translate}}</span>
    </div>
    <div class="live-game__info live-game-info">
        <span class="live-game-info__user">{{game.lolAccount?.name}}</span>
        <span class="live-game-info__time">
            <span class="live-icon"></span>
            <!-- {{game.durationMinutes}}:{{game.durationSeconds}} -->
            32:23
        </span>
    </div>
</section>