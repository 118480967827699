import { NgModule } from "@angular/core";
import { TimeFromNowPipe } from "./pipes/time-from-now.pipe";
import { ShortNumberPipe } from "./pipes/short-number.pipe";
import { NotificationService } from "./notification.service";
import { DatePipe } from '@angular/common';

@NgModule({
    imports: [
    ],
    declarations: [
        TimeFromNowPipe,
        ShortNumberPipe,
    ],
    exports: [
        TimeFromNowPipe,
        ShortNumberPipe,
    ],
    providers: [
        NotificationService,
        DatePipe
    ]
})
export class UtilsModule {
}
