import { Component, Input, OnInit } from "@angular/core";
import { RoomAchievement } from '../../models/room/room-achievement';

@Component({
    selector: 'achievement-card',
    templateUrl: './achievement-card.component.html',
    styleUrls: ['./achievement-card.component.scss']
})
export class AchievementCardComponent implements OnInit {

    @Input() size: string = 'large'; // small - large

    @Input() achievement: RoomAchievement;

    ngOnInit() {
    }

    // TODO: VER COMO MANEJAMOS LOS ACHIEVEMENTS (FORMATOS Y TIPOS)
    // Hacer un archivo de config para iconos? 
}

