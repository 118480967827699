import { Component, Input, OnInit } from "@angular/core";
import * as moment from 'moment';

@Component({
    selector: 'live-game',
    templateUrl: './live-game.component.html',
    styleUrls: ['./live-game.component.scss']
})
export class LiveGameComponent implements OnInit {

    @Input() game: any;

    championIconUrl: string;

    ngOnInit() {
        this.championIconUrl = `http://ddragon.leagueoflegends.com/cdn/12.23.1/img/champion/${this.game.champion}.png`;

        // TODO: Check backend format
        // this.game.startAt = [2022, 7, 19, 0, 14, 1, 55000000];
        if (this.game.startAt && this.game.startAt.length > 5) {
            this.game.startAtDate = new Date(
                this.game.startAt[0],
                this.game.startAt[1] - 1,
                this.game.startAt[2],
                this.game.startAt[3],
                this.game.startAt[4],
                this.game.startAt[5]
            )
        }
        let durationInSeconds = moment(new Date).diff(moment(this.game.startAtDate), 'seconds');
        this.game.durationMinutes = Math.floor(durationInSeconds / 60);
        this.game.durationSeconds = durationInSeconds - 60 * this.game.durationMinutes;

    }

}

