import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { NotificationSnackbarComponent } from '../components/snackbar-component/notification-snackbar.component';
import { NotificationType } from '../types/utils/score-type';


@Injectable()
export class NotificationService {
    private currentSnackbar: MatSnackBarRef<any>;

    constructor(private snackbar: MatSnackBar) {
    }

    notify(type: NotificationType, message: string, title?: string) {
        const config = new MatSnackBarConfig();
        config.data = { title: title, message: message };
        config.duration = 5000;
        config.panelClass = [this.getClassByType(type)];
        //
        if (this.currentSnackbar != null) {
            this.currentSnackbar.dismiss();
        }
        this.currentSnackbar = this.snackbar.openFromComponent(NotificationSnackbarComponent, config);
    }

    notifyMessage(message: string) {
        const config = new MatSnackBarConfig();
        config.duration = 5000;
        //
        const snackbarRef = this.snackbar.open(message, 'Dismiss', config);
        snackbarRef.onAction().subscribe(() => {
            this.snackbar.dismiss();
        });
    }

    public dismissCurrentSnackbar() {
        if (this.snackbar != null) {
            this.snackbar.dismiss();
        }
    }

    private getClassByType(type: string): string {
        switch (type) {
            case 'SUCCESS': return 'snackbar--success';
            case 'ERROR': return 'snackbar--error';
            case 'INFO': return 'snackbar--info';
            default: return '';
        }
    }
}