import {Component, Inject} from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';


@Component({
    selector: 'notification-snackbar',
    templateUrl: './notification-snackbar.component.html',
    styleUrls: ['./notification-snackbar.component.scss']
})
export class NotificationSnackbarComponent {

    title: string;
    message: string;
    classByType: string;

    constructor(public snackBarRef: MatSnackBarRef<NotificationSnackbarComponent>, @Inject(MAT_SNACK_BAR_DATA) public data: any) {
        this.title = data.title ? data.title : null;
        this.message = data.message;

        if (this.message.length > 250) {
            this.message = this.message.substring(0, 250) + '...';
        }
    }

    onDismissClick() {
        this.snackBarRef.dismiss();
    }
    
}
