import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { HomeComponent } from './feature/home/home.component';
import { LoginComponent } from './feature/session/login/login.component';
import { SignUpComponent } from './feature/session/sign-up/sign-up.component';
import { RoomsListComponent } from './feature/main/rooms/rooms-list/rooms-list.component';

export const appRoutes: Routes = [
    { path: 'session', redirectTo: 'session/login', pathMatch: 'full' },
    { path: 'session/login', component: LoginComponent },
    { path: 'session/sign-up', component: SignUpComponent },
    {
        path: '',
        // loadChildren: 'app/feature/main/main.module#MainModule'
        loadChildren: () => import('./feature/main/main.module').then(m => m.MainModule)
    },
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule],
    providers: []
})
export class AppRoutingModule {
}
