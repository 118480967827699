<section class="achievement" [ngClass]="{'achievement_column': size === 'small'}">
    <div class="achievement__info">
        <span class="achievement__info-value">
            <img src="../../../../assets/icons/sword.svg" class="icon">
            <span class="achievement__info-value-text">{{achievement.value}}</span>
        </span>
        <span class="achievement__info-label">{{achievement.achievementName | translate}}</span>
    </div>
    <div class="achievement__data">
        <summoner-tag [summoner]="achievement.account"></summoner-tag>
        <champion-tag class="achievement__data-champion" *ngIf="achievement.champion" [champion]="achievement.champion"></champion-tag>
        <span class="achievement__data-date">{{achievement.date | date:'dd/MM/yyyy HH:mm:ss'}}</span>
    </div>

</section>