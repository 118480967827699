import { Component, HostListener, Input, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { RoomsService } from '../../../core/services/rooms/rooms.service';

@Component({
    selector: 'streak-position',
    templateUrl: './streak-position.component.html',
    styleUrls: ['./streak-position.component.scss']
})
export class StreakPositionComponent implements OnInit {

    @Input() streakPosition: number;

    constructor() { }

    ngOnInit() {

    }


  getStreakText(): string {
    if (this.streakPosition < 0) {
      return `${Math.abs(this.streakPosition)} lowered positions`;
    } else if (this.streakPosition > 0) {
      return `${this.streakPosition} raised positions`;
    } else {
      return 'Same position';
    }
  }

}
