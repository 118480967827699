<div class="snackbar" *ngIf="message != null">

    <mat-icon class="snackbar__icon">error</mat-icon>

    <div class="snackbar__container">
        <div class="snackbar__title" *ngIf="title != null">
            {{title}}
        </div>
        <div class="snackbar__message">{{message}}</div>
    </div>

    <div class="snackbar__action" (click)="onDismissClick()">
        <span class="snackbar__action-txt">Dismiss</span>
    </div>

</div>
