import {ErrorStateMatcher} from '@angular/material/core';
import {FormControl, FormGroupDirective, NgForm} from '@angular/forms';

// Error State Matcher that triggers the error state when the field is invalid.
// Default ErrorStateMatcher triggers the error when the field gets unfocused.
export class CustomErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return control?.invalid && (control.dirty || control.touched || form?.submitted);
    }
}
