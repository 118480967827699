import { NgModule } from '@angular/core';
import { RestService } from './services/http/rest.service';
import { HttpClientModule } from '@angular/common/http';
import { UserSessionService } from './services/user/user-session.service';
import { UserSessionStorage } from './storage/user-session.storage';
import { MainAuthGuard } from './guards/main-auth.guard';
import { RoomsService } from './services/rooms/rooms.service';

@NgModule({
    imports: [HttpClientModule],
    declarations: [],
    providers: [
        RestService,
        UserSessionService,
        UserSessionStorage,
        RoomsService,
        MainAuthGuard
    ]
})
export class CoreModule {
}
