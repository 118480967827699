<mat-card class="game-recap" [ngClass]="{'victory': game.win, 'defeat': !game.win}">
  <mat-grid-list [cols]="showSummoner ? 6 : 5" rowHeight="60px">
    <mat-grid-tile>
      <div class="game-recap__champion">
        <champion-tag [champion]="game.champion" [lane]="game.lane"></champion-tag>
      </div>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="showSummoner" >
      <div class="game-recap__user" *ngIf="showSummoner">
        <summoner-tag [summoner]="game"></summoner-tag>
      </div>
    </mat-grid-tile>

    <mat-grid-tile>
      <div class="game-recap__kda">
        {{game.kills}}/{{game.deaths}}/{{game.assists}}
        <!-- TODO component/colors -->
        <span class="badge mt-2" style="background-color: var(--color-game-defeat)" *ngIf="game.pentaKills > 0">PENTAKILL</span>
        <span class="badge mt-2" style="background-color: var(--color-status-in-progress)" *ngIf="game.quadraKills > 0 && game.pentaKills === 0">QUADRAKILL</span>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
          <div class="game-recap__kda-stats">
              <color-number label="KDA" [value]="game.kda" class="mb-2">KDA {{game.kda | number:'1.0-2'}}</color-number>
              <color-number label="KP" [value]="game.kp" >KP {{game.kp / 100 | percent}}</color-number>
          </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="game-recap__score" [matMenuTriggerFor]="menu">
        <span [ngClass]="{'victory-score': game.win, 'defeat-score': !game.win}">{{game.score > 0 ? '+' : ''}}{{game.score}}</span>
        <small [ngClass]="{'victory-score': game.win, 'defeat-score': !game.win}">  pts</small>
      </div>
      <mat-menu #menu="matMenu" xPosition="before" yPosition="below">
        <ng-container [ngTemplateOutlet]="scoreDetailsTemplate" [ngTemplateOutletContext]="{game: game}"></ng-container>
      </mat-menu>
    </mat-grid-tile>
    <mat-grid-tile><div class="game-recap__time">
      <div class="time">
        <mat-icon>watch_later</mat-icon>
        <span>{{game.durationString}}</span>
      </div>
      <span matTooltip="{{game.endDate | date: 'yyyy-MM-dd HH:mm'}}">{{game.endDate | timeFromNow}}</span>
    </div></mat-grid-tile>
  </mat-grid-list>
</mat-card>

<!--TODO component-->
<ng-template #scoreDetailsTemplate let-game="game">
  <mat-card class="score-details">
    <mat-grid-list cols="4" rowHeight="25px">

      <!--  Base Score -->
      <mat-grid-tile colspan="3">
        Base Score
      </mat-grid-tile>
      <mat-grid-tile colspan="1">
        <span class="base-score" [ngClass]="{ 'victory-score': game.baseScore > 0, 'defeat-score': game.baseScore < 0 }"> {{ game.baseScore }}</span>
      </mat-grid-tile>

      <div *ngIf="game.rules !== null && game.rules !== undefined && game.rules.length > 0">
        <!-- Divider -->
        <mat-grid-tile colspan="4">
          <hr class="divider-score">
        </mat-grid-tile>

        <mat-grid-tile class="rules-title" colspan="4">
          Rules
        </mat-grid-tile>
        <!--  Rules -->
        <div *ngFor="let rule of game.rules" class="rule-item">

          <mat-grid-tile colspan="3">
            <span class="rule-description">{{ getRule(rule) }}</span>
          </mat-grid-tile>

          <mat-grid-tile colspan="1">
            <span class="rule-points" [ngClass]="{ 'victory-score': rule.points > 0, 'defeat-score': rule.points < 0 }">
              {{ rule.points > 0 ? '+' + rule.points : rule.points }}
            </span>
          </mat-grid-tile>

        </div>
      </div>


      <!-- Divider -->
      <div *ngIf="game.cappedScore">

        <mat-grid-tile colspan="4">
          <hr class="divider-score">
        </mat-grid-tile>

        <!--  Capped -->
        <mat-grid-tile colspan="4">
          <span class="capped-score">CAPPED</span>
        </mat-grid-tile>
      </div>

    </mat-grid-list>
  </mat-card>


</ng-template>



<!--<section class="game-recap"  [ngClass]="{'victory': game.win, 'defeat': !game.win}">-->
<!--    <div class="game-recap__time">-->
<!--        <div class="time">-->
<!--            <mat-icon>watch_later</mat-icon>-->
<!--            <span>{{game.durationString}}</span>-->
<!--        </div>-->
<!--        {{game.endDate | timeFromNow}}-->
<!--    </div>-->
<!--    <div class="game-recap__user" *ngIf="showSummoner">-->
<!--        <summoner-tag [summoner]="game"></summoner-tag>-->
<!--    </div>-->
<!--    <div class="game-recap__champion">-->
<!--        <champion-tag [champion]="game.champion" [lane]="game.lane"></champion-tag>-->
<!--    </div>-->
<!--    <div class="game-recap__kda">-->
<!--        {{game.kills}}/{{game.deaths}}/{{game.assists}}-->
<!--    </div>-->
<!--    <div class="game-recap__kda-stats">-->
<!--        <span class="secondary-text mb-2">KDA {{game.kda | number:'1.0-2'}}</span>-->
<!--        <span class="primary-text">KP {{game.kp / 100 | percent}}</span>-->
<!--    </div>-->
<!--    &lt;!&ndash; <div class="game-recap__stats">-->
<!--        STATS-->
<!--    </div> &ndash;&gt;-->
<!--    <div class="game-recap__score">-->
<!--        <span>{{game.score > 0 ? '+' : ''}}{{game.score}}</span>-->
<!--        <small>pts</small>-->
<!--    </div>-->
<!--</section>-->
