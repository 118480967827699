import { Component, HostBinding, Input, OnInit } from "@angular/core";
import { QueueType } from 'src/app/shared/types/lol/queue-type';

@Component({
    selector: 'room-queue-tag',
    template: '{{roomQueueType | translate}}',
    styleUrls: ['./room-queue-tag.component.scss']
})
export class RoomQueueTagComponent implements OnInit {

    @Input() roomQueueType: QueueType;

    queueType = QueueType;

    // Class of the 'host' => <room-queue-tag>
    @HostBinding('class') tagClass: string;

    ngOnInit() {
        switch (this.roomQueueType) {
            case QueueType.SOLOQ:
                this.tagClass = 'soloq';
                break;
            case QueueType.ARAM:
                this.tagClass = 'aram';
                break;
            case QueueType.FLEX:
                this.tagClass = 'flex';
                break;
            case QueueType.NORMAL:
                this.tagClass = 'normal';
                break;
            case QueueType.NORMAL_DRAFT:
                this.tagClass = 'normal-draft';
                break;
        }

    }


}