  <mat-card *ngIf="show"  [style]="{'background': 'linear-gradient(to top, var(--color-bg-dark-light) 0%, ' +
   'var(--color-bg-dark-light) 25%, rgba(255,255,255,0) 100%), url(' + backgroundUrl + ') no-repeat center center','background-size': 'cover'}">
    <div *ngIf="isEmpty" class="overlay"></div>
    <mat-card-content>
      <div class="first-row">
        <div class="summoners" *ngIf="!isEmpty">
          <div *ngFor="let account of accounts">
            <div class="summoner">
              <summoner-tag [summoner]="account"></summoner-tag>
            </div>
          </div>
        </div>

      </div>
      <div class="second-row">
        <div>
          <p class="text-3">{{text3}}</p>
        </div>
        <div class="text-1-4" >
          <p class="text-1">{{text1}}</p>
          <p class="text-4" [style.margin-left.px]="isEmpty ? 0 : 3">{{text4}}</p>
        </div>
        <div >
          <p class="text-2">{{text2}}</p>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
