import { Component, Input, OnInit } from "@angular/core";
import { StringUtils } from '../../utils/string-utils';

@Component({
    selector: 'champion-tag',
    templateUrl: './champion-tag.component.html',
    styleUrls: ['./champion-tag.component.scss']
})
export class ChampionTagComponent implements OnInit {

    @Input() champion: any;
    @Input() lane: any;

    championIconUrl: string;

    ngOnInit() {
        this.championIconUrl = `https://ddragon.leagueoflegends.com/cdn/img/champion/tiles/${this.parseChampionName(this.champion.toLowerCase())}_0.jpg`;
    }

    private parseChampionName(name: string): string{
        let parts = name.split('_');
        parts = parts.map(part => StringUtils.capitalize(part));
        return parts.join('');
    }

}
