import { Component, HostListener, Input, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { RoomsService } from '../../../core/services/rooms/rooms.service';

@Component({
    selector: 'streak',
    templateUrl: './streak.component.html',
    styleUrls: ['./streak.component.scss']
})
export class StreakComponent implements OnInit {

    @Input() streak: number;

    constructor() { }

    ngOnInit() {

    }


  getStreakText(): string {
    if (this.streak <= -3) {
      return `${Math.abs(this.streak)} losses`;
    } else if (this.streak >= 3) {
      return `${this.streak} wins`;
    } else {
      return '';
    }
  }

  shouldDisplayIcon(): boolean {
    return this.streak <= -3 || this.streak >= 3;
  }

}
