import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserSessionStorage } from '../../storage/user-session.storage';

@Injectable()
export class RestService {

    constructor(private http: HttpClient, private userSessionStorage: UserSessionStorage) {
    }

    securedGet(url: string): Promise<any> {
        return this.get(url, true);
    }

    get(url: string, secured?: boolean): Promise<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        //
        if (secured) {
            headers = headers.append('Authorization', this.userSessionStorage.getAccessToken());
        }
        //
        return this.http.get(url, { headers: headers, responseType: 'text' }).toPromise().then(
            res => {
                if (res != null && res.length > 0) {
                    return JSON.parse(res);
                } else {
                    return '';
                }
            }
        ).catch(
            error => {
                return Promise.reject(this.handleError(error));
            }
        )
    }

    securedPost(url: string, body: any, form?: boolean): Promise<any> {
        return this.post(url, body, true, form);
    }

    post(url: string, body: any, secured?: boolean, form?: boolean): Promise<any> {
        let headers = new HttpHeaders();
        //
        if (!form) {
            headers = headers.append('Content-Type', 'application/json');
        }
        //
        if (secured) {
            headers = headers.append('Auth', this.userSessionStorage.getAccessToken());
        }
        //
        return this.http.post(url, body, { headers: headers, responseType: 'text' }).toPromise().then(
            res => {
                if (res != null && res.length > 0) {
                    return JSON.parse(res);
                } else {
                    return '';
                }
            }).catch(error => {
                return Promise.reject(this.handleError(error));
            }
            )
    }

    securedPut(url: string, body: any, form?: boolean): Promise<any> {
        return this.put(url, body, true, form);
    }

    put(url: string, body: any, secured?: boolean, form?: boolean): Promise<any> {
        let headers = new HttpHeaders();
        //
        if (!form) {
            headers = headers.append('Content-Type', 'application/json');
        }
        //
        if (secured) {
            headers = headers.append('Auth', this.userSessionStorage.getAccessToken());
        }
        //
        return this.http.put(url, body, { headers: headers, responseType: 'text' }).toPromise().then(
            res => {
                if (res != null && res.length > 0) {
                    return JSON.parse(res);
                } else {
                    return '';
                }
            }).catch(error => {
                return Promise.reject(this.handleError(error));
            }
            )
    }

    securedDelete(url: string): Promise<any> {
        return this.delete(url, true);
    }

    delete(url: string, secured?: boolean): Promise<any> {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        //
        if (secured) {
            headers = headers.append('Auth', this.userSessionStorage.getAccessToken());
        }
        //
        return this.http.delete(url, { headers: headers, responseType: 'text' }).toPromise().then(
            res => {
                if (res != null && res.length > 0) {
                    return JSON.parse(res);
                } else {
                    return '';
                }
            }).catch(error => {
                return Promise.reject(this.handleError(error));
            }
            )
    }

    handleError(error: any) {
        error.error = JSON.parse(error.error);
        if (error.error != null && error.error.errors != null && error.error.errors[0] != null) {
            const errorServerBody = error.error.errors[0];
            error.title = errorServerBody.title;
            error.detail = errorServerBody.detail;
        }
        return error;
    }
}
