import {AbstractControl, ValidatorFn} from '@angular/forms';

export class PasswordValidator {
    static validatePassword(control: AbstractControl): { [key: string]: boolean } | boolean {
        //
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w]))\S{8,75}$/;
        const LOWECASE_REGEX = /[a-z]/;
        const UPPERCASE_REGEX = /[A-Z]/;
        const DIGIT_REGEX = /[0-9]/;
        const SPECIAL_REGEX = /[\W]/;
        //
        const password = control.value;
        if (password === '') {
            return false;
        }
        //
        const result: any = {};
        //
        if(password.length < 8){
            // result.minlength = true;
            result.minlength = {requiredLength: 8, actualLength: password.length};


        }
        if (!LOWECASE_REGEX.test(password)) {
            result.missingLowercase = true;
        }
        if (!UPPERCASE_REGEX.test(password)) {
            result.missingUppercase = true;
        }
        if (!DIGIT_REGEX.test(password)) {
            result.missingDigit = true;
        }
        if (!SPECIAL_REGEX.test(password)) {
            result.missingSpecialCharacter = true;
        }

        return result;
    }

}
