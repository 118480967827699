import { Component, HostListener, Input, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { RoomsService } from '../../../core/services/rooms/rooms.service';

@Component({
    selector: 'summoner-tag',
    templateUrl: './summoner-tag.component.html',
    styleUrls: ['./summoner-tag.component.scss']
})
export class SummonerTagComponent implements OnInit {

    @Input() summoner: any;

    iconUrl: string;
    roomId: number;
    name: string;

    constructor(private roomsService: RoomsService, private router: Router) { }

    ngOnInit() {
        if (this.summoner.accountIcon !== undefined){
          this.iconUrl = `https://ddragon.leagueoflegends.com/cdn/14.2.1/img/profileicon/${this.summoner.accountIcon}.png`;
        }
        if (this.summoner.iconId  !== undefined){
          this.iconUrl = `https://ddragon.leagueoflegends.com/cdn/14.2.1/img/profileicon/${this.summoner.iconId}.png`;
        }

        if (this.summoner.name !== undefined){
          this.name = this.summoner.name;
        }

      if (this.summoner.accountName !== undefined){
          this.name = this.summoner.accountName;
      }


        this.roomId = this.roomsService.getRoomId();
    }

    handleImageError() {
      this.iconUrl = 'https://ddragon.leagueoflegends.com/cdn/12.19.1/img/profileicon/29.png';
    }
}
