import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: 'color-number',
  templateUrl: './color-number.component.html',
  styleUrls: ['./color-number.component.scss']
})
export class ColorNumberComponent implements OnInit {

  @Input() value: number;
  @Input() label: string;

  colorClass: string;

  constructor() { }

  ngOnInit() {
    this.setColorClass();
  }

  setColorClass() {
    if (this.label === 'KDA') {
      this.colorClass = this.calculateKdaColor(this.value);
    } else if (this.label === 'KP') {
      this.colorClass = this.calculateKpColor(this.value);
    } else {
      this.colorClass = 'default-color';
    }
  }

  calculateKdaColor(value: number): string {
    if (value >= 7) {
      return 'high-color';
    } else if (value >= 5) {
      return 'medium-color';
    } else if (value >= 3) {
      return 'low-color';
    }else {
      return 'default-color';
    }
  }

  calculateKpColor(value: number): string {
    if (value >= 80) {
      return 'high-color';
    } else if (value >= 70) {
      return 'medium-color';
    } else if (value >= 60) {
      return 'low-color';
    } else {
      return 'default-color';
    }
  }
}
