<mat-card class="room-card room-card_status" [ngClass]="{'room-card_status-starting': room.status == roomStatus.STARTING,
                'room-card_status-in-progress': room.status == roomStatus.IN_PROGRESS,
                'room-card_status-cancelled': room.status == roomStatus.CANCELLED,
                'room-card_status-paused': room.status == roomStatus.PAUSED,
                'room-card_status-finished': room.status == roomStatus.FINISHED}">

    <div class="room-card__image">
        <img [src]="room.queueType == queueType.ARAM ? 
                    '../../../../assets/images/maps/aram-map.png' :
                    '../../../../assets/images/maps/normal-map.png'" width="100%" height="100%">
    </div>

    <div class="room-card__content">

        <div class="room-card__header">
            <room-queue-tag class="room-card__queue" [roomQueueType]="room.queueType"></room-queue-tag>

            <div class="room-card__players">
                <mat-icon class="room-card__players-icon">people</mat-icon>
                <span class="room-card__players-number">{{room.participants}}</span>
            </div>
        </div>



        <div class="room-card__info">
            <span class="room-card__name">{{room.name}}</span>
            <span class="room-card__owner" *ngIf="room.ownerNickname"> by {{room.ownerNickname}}</span>
        </div>

        <div class="room-card__details">
            <span>
                <mat-icon class="room-card__detail-icon">public</mat-icon>
                {{room.region}}
            </span>

            <span>
                <mat-icon class="room-card__detail-icon">poll</mat-icon>
                {{room.scoreMode | translate}}
            </span>

            <span>
                <mat-icon class="room-card__detail-icon">flag</mat-icon>
                {{room.status | translate}}
            </span>

            <span>
                <mat-icon class="room-card__detail-icon">calendar_month</mat-icon>
                {{room.startDate | date:'dd/MM/yyyy'}}
                <span *ngIf="room.endDate"> - </span>
                {{room.endDate | date:'dd/MM/yyyy'}}
            </span>

            <a class="room-card__view-more" [routerLink]="['/rooms/' + room.id]" (click)="viewRoom()">
                <mat-icon> chevron_right </mat-icon>
            </a>

        </div>

    </div>

</mat-card>