<ng-container>
  <span [matTooltip]="getStreakText()" matTooltipPosition="after">
    <i *ngIf="streakPosition >= 3" class="fas fa-angle-double-up streak-position-icon up-streak"></i>
    <i *ngIf="streakPosition > 0 && streakPosition < 3" class="fas fa-angle-up streak-position-icon up-streak"></i>
    <i *ngIf="streakPosition < 0 && streakPosition > -3" class="fas fa-angle-down streak-position-icon down-streak"></i>
    <i *ngIf="streakPosition <= -3" class="fas fa-angle-double-down streak-position-icon down-streak"></i>
    <i *ngIf="streakPosition === 0" class="fas fa-genderless streak-position-icon same-streak"></i>
  </span>
</ng-container>

