import {NgModule} from '@angular/core';
import {LoginComponent} from './login/login.component';
import {SignUpComponent} from './sign-up/sign-up.component';
import {SharedModule} from '../../shared/shared.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {RouterModule} from '@angular/router';
import {MatDatepickerModule} from '@angular/material/datepicker';

@NgModule({
    imports: [
        SharedModule,

    ],
    declarations: [
        LoginComponent,
        SignUpComponent
    ],
    providers: []
})
export class SessionModule {
}
