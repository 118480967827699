import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../../environments/environment';
import { User } from '../../../shared/models/user/user';
import { UserSessionStorage } from '../../storage/user-session.storage';
import { RestService } from '../http/rest.service';
import { UserData } from 'src/app/shared/models/user/user-data';

@Injectable()
export class UserSessionService {

    private currentUserSubject: BehaviorSubject<User>;
    public currentUser$: Observable<User>; // Observable to subscribe

    constructor(private restService: RestService, private userSessionStorage: UserSessionStorage) {
        this.currentUserSubject = new BehaviorSubject<User>(this.userSessionStorage.getUser());
        this.currentUser$ = this.currentUserSubject.asObservable();
    }

    public get currentUser(): User {
        return this.currentUserSubject.getValue();
    }

    login(username: string, password: string, rememberMe: boolean) {
        const url = `${environment.apiBase}/user/login`;
        return this.restService.post(url, { username: username, password: password })
            .then(res => {
                console.log(`Login successfully. Token: ${res.authorization.accessToken}`);
                this.userSessionStorage.setUserAuthorization(res, rememberMe);
                this.currentUserSubject.next(res.user);
            });
    }

    register(user: any) {
        const url = `${environment.apiBase}/user/register`;
        return this.restService.post(url, user).then(res => {
            this.userSessionStorage.setUserAuthorization(res, true);
            this.currentUserSubject.next(res.user);
        });
    }

    async getUserData(): Promise<UserData>{
        const url = `${environment.apiBase}/user/`;
        return await this.restService.securedGet(url);
    }

    logout() {
        this.userSessionStorage.dropSession();
        this.currentUserSubject.next(null);
    }
}
