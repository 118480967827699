import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {UserSessionService} from '../services/user/user-session.service';
import {UserSessionStorage} from '../storage/user-session.storage';

@Injectable()
export class MainAuthGuard implements CanActivate {
    constructor(private userSessionService: UserSessionService, private userSessionStorage: UserSessionStorage, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const userAuthorization = this.userSessionStorage.getUserAuthorization();
        // // Handle token expiration.
        if (userAuthorization == null || userAuthorization?.authorization.accessToken == null) {
            // Handle redirect to url.
            this.router.navigate(['/home']);
            return false;
        }
        return true;
    }
}
